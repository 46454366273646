<template>
  <div class="px-2">
    <filter-slot
      :filter="[]"
      :total-rows="FilterSlot.totalRows"
      :paginate="FilterSlot.paginate"
      :start-page="FilterSlot.startPage"
      :to-page="FilterSlot.toPage"
      :filter-principal="FilterSlot.filterPrincipal"
      @reload="$refs['documentServiceType'].refresh()"
    >
      <template #buttons>
        <div class="ml-2">
          <b-button variant="primary" @click="openModalCreateServiceType()">
            <feather-icon icon="PlusIcon" size="15" class="mr-50" />Create
          </b-button>
        </div>
      </template>
      <b-table
        ref="documentServiceType"
        slot="table"
        class="blue-scrollbar"
        primary-key="id"
        empty-text="No matching records found"
        responsive="md"
        sticky-header="50vh"
        small
        show-empty
        no-provider-filtering
        :busy.sync="isBusy"
        :fields="Fields.DocumentServiceTypeFields"
        :items="myProvider"
        :per-page="FilterSlot.paginate.perPage"
        :current-page="FilterSlot.paginate.currentPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(name)="data">
          <span>{{ data.item.name }}</span>
          <feather-icon
            v-if="data.item.description"
            icon="InfoIcon"
            class="clickable ml-1 text-primary"
            size="17"
            v-b-tooltip.hover.right="data.item.description"
          />
        </template>
        <template #cell(type)="data">
          <b-badge v-if="data.item.type === 'ANSWER'"
            variant="light-warning"
          >
            {{ data.item.type }}
          </b-badge>
          <b-badge v-if="data.item.type === 'INVESTIGATION'"
            variant="light-primary"
          >
            {{ data.item.type }}
          </b-badge>
          <b-badge v-if="data.item.type === 'OTHER'"
            variant="light-secondary"
          >
            {{ data.item.type }}
          </b-badge>
        </template>
        <template #cell(is_active)="data">
          <b-badge
            :variant="data.item.is_active ? 'light-success' : 'light-danger'"
          >
            {{ data.item.is_active ? "ACTIVE" : "INACTIVE" }}
          </b-badge>
        </template>
        <template #cell(created_by)="data">
          <span>{{ data.item.created_by }}</span> <br />
          <span>{{ data.item.created_at }}</span>
        </template>
        <template #cell(action)="data">
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon
              :icon="data.item.is_active ? 'SlashIcon' : 'CircleIcon'"
              class="clickable mr-1"
              :class="data.item.is_active ? 'text-danger' : 'text-success'"
              size="17"
              @click="changeStatus(data.item)"
              v-b-tooltip.hover.left="
                data.item.is_active ? 'INACTIVE' : 'ACTIVE'
              "  
            />

            <feather-icon
              icon="EditIcon"
              class="cursor-pointer mr-1 text-info"
              size="17"
              @click="openModalEditServiceType(data.item)"
            />

            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer text-danger"
              size="17"
              @click="deleteServiceType(data.item)"
            />
          </div>
        </template>
      </b-table>
    </filter-slot>
    <modal-service-type
      v-if="openModalServiceType"
      @close="openModalServiceType = false"
      @closing="closeModalServiceType()"
      :serviceTypeId="serviceTypeId"
    />
  </div>
</template>

<script>
import Fields from "@/views/debt-solution/views/settings/views/documents-config/document-service-type/data/fields.data.js";
import modalServiceType from "@/views/debt-solution/views/settings/views/documents-config/document-service-type/components/modal/ModalServiceType.vue";
import DocumentServideTypeService from "@/views/debt-solution/views/settings/views/documents-config/document-service-type/service/document-service-type.service.js";
export default {
  components: {
    modalServiceType,
  },
  data() {
    return {
      openModalServiceType: false,
      serviceTypeId: null,
      Fields,
      isBusy: false,
      FilterSlot: {
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: 0,
        toPage: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Name...",
          model: "",
        },
      },
    };
  },
  methods: {
    async myProvider() {
      try {
        this.addPreloader();
        const data = await DocumentServideTypeService.getDocumentsServiceType({
          serach_text: this.FilterSlot.filterPrincipal.model,
          page: this.FilterSlot.paginate.currentPage,
          perPage: this.FilterSlot.paginate.perPage,
        });

        this.FilterSlot.totalRows = data.total;
        this.FilterSlot.startPage = data.from ? data.from : 0;
        this.FilterSlot.toPage = data.to ? data.to : 0;
        this.FilterSlot.paginate.currentPage = data.current_page;
        this.FilterSlot.paginate.perPage = data.per_page;
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async changeStatus(item) {
      try {
        const confirm = await this.showConfirmSwal(
          `Are you sure you want to ${
            item.is_active ? "inactive" : "active"
          } this item?`
        );
        if (confirm.isConfirmed) {
          const data =
            await DocumentServideTypeService.updateDocumentServiceType({
              id: item.id,
              name: item.name,
              description: item.description,
              is_active: item.is_active ? 0 : 1,
              type: item.type,
            });
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Document service type status change successfully"
          );
          this.$refs["documentServiceType"].refresh();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    openModalCreateServiceType() {
      this.serviceTypeId = null;
      this.openModalServiceType = true;
    },
    openModalEditServiceType(item) {
      this.serviceTypeId = item.id;
      this.openModalServiceType = true;
    },
    closeModalServiceType() {
      this.$refs["documentServiceType"].refresh();
      this.openModalServiceType = false;
    },
    async deleteServiceType(item) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          await DocumentServideTypeService.deleteDocumentServiceType({
            id: item.id,
          });
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
          this.$refs["documentServiceType"].refresh();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style scoped>
</style>
