const DocumentServiceTypeFields = [
    {
        key: "name",
        label: "Name",
        sortable: false,
    },
    {
        key: "type",
        label: "Type",
        sortable: false,
    },
    {
        key: "is_active",
        label: "Status",
        sortable: false,
    },
    {
        key: "created_by",
        label: "Created By",
        sortable: false,
    },
    {
        key: "action",
        thClass: "text-center",
        tdClass: "text-center",
    }

];

export default {
    DocumentServiceTypeFields,
};