<template>
  <b-modal
    ref="modalServiceType"
    :title="serviceTypeId ? 'EDIT SERVICE TYPE' : 'CREATE NEW SERVICE TYPE'"
    title-tag="h3"
    modal-class="modal-primary"
    no-close-on-backdrop
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-row>
        <b-col cols="12">
          <b-form-group label="Name:">
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:1|max:255"
            >
              <b-form-input
                v-model="name"
                maxlength="255"
                :state="errors.length > 0 ? false : null"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Name {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Type:">
            <validation-provider v-slot="{ errors }" rules="required">
              <v-select
                placeholder="Select Type"
                v-model="type"
                :options="['ANSWER', 'INVESTIGATION', 'OTHER']"
                :class="errors[0] ? 'select__error' : ''"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Type {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Description:">
            <validation-provider>
              <b-form-textarea v-model="description" maxlength="255" />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <button-save v-if="serviceTypeId" @click="updateServiceType()" />
      <button-save v-else @click="createServiceType()" />
    </template>
  </b-modal>
</template>
<script>
import buttonSave from "@/views/commons/utilities/ButtonSave.vue";
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import DocumentServideTypeService from "@/views/debt-solution/views/settings/views/documents-config/document-service-type/service/document-service-type.service.js";
import { mapGetters } from "vuex";
export default {
  components: {
    buttonSave,
    buttonCancel,
  },
  props: {
    serviceTypeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      name: "",
      description: "",
      is_active: null,
      type: null,
    };
  },
  mounted() {
    this.toggleModal("modalServiceType");
    if (this.serviceTypeId) {
      this.getServiceTypeById();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async createServiceType() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const data =
            await DocumentServideTypeService.createDocumentServiceType({
              name: this.name,
              description: this.description,
              created_by: this.currentUser.user_id,
              type: this.type,
            });
          this.showSuccessSwal();
          this.$emit("closing");
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getServiceTypeById() {
      try {
        const data =
          await DocumentServideTypeService.getDocumentsServiceTypeById({
            id: this.serviceTypeId,
          });
        this.name = data[0].name;
        this.description = data[0].description;
        this.is_active = data[0].is_active;
        this.type = data[0].type;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async updateServiceType() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const data =
            await DocumentServideTypeService.updateDocumentServiceType({
              id: this.serviceTypeId,
              name: this.name,
              description: this.description,
              is_active: this.is_active,
              type: this.type,
            });
          this.showSuccessSwal();
          this.$emit("closing");
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
<style scoped>
.select__error {
  border: 1px solid #f1556c !important;
  border-radius: 5px;
}
</style>